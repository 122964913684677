import FilterPeople from "./peopleFilters/PeopleFilter";
import FilterPost from "./postFilters/PostFilter";

import People from "./peopleFilters/PeopleSearch";
import Post from "./postFilters/PostSearch";

export const PostFilter = FilterPost;
export const PeopleFilter = FilterPeople;

export const PostComponent = Post;
export const PeopleComponent = People;
